import axiosInstance from "./axiosInstance";
import { axiosInstanceSecured } from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

export const getAllStores = async (ConfigType, Brand, Club) => {
  try {
    const response = await axiosInstanceSecured.post(
      `${API_ENDPOINTS.STORE.GETALLSTORES}`,
      {
        storeId: 0,
        location: null,
        pageNumber: 0,
        pageSize: 0,
        status: true
      }
    );
    return response.data;
  } catch (error) {
    if (error?.response && error?.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const GetStoreAvailableData = async (Id) => {
  try {
    const response = await axiosInstanceSecured.post(
      API_ENDPOINTS.STORE.GET_STORE_AVAILABLE,
      {
        storeId: Id,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const getAppointmentSlot = async (SubServiceId, storeId, Date) => {
  try {
    const response = await axiosInstanceSecured.post(
      API_ENDPOINTS.STORE.GET_STORE_SLOTS.toString(),
      {
        serviceSubTypeId: SubServiceId,
        storeId: storeId,
        appointmentDate: Date,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const createAppointment = async (
  serviceTypeID,
  serviceSubTypeID,
  firstName,
  lastName,
  emailId,
  mobileNo,
  storeId,
  appointmentDate,
  appointmentFromTime,
  appointmentoTime,
  appointmentType,
  appointmentStatus,
  isMail,
  isSMS
) => {
  try {
    const response = await axiosInstanceSecured.post(
      API_ENDPOINTS.Appoinment.createAppointment.toString(),
      {
        appointmentDetails: {
          serviceTypeId: serviceTypeID,
          serviceSubTypeId: serviceSubTypeID,
          expertId: 0,
          firstName: firstName,
          lastName: lastName,
          emailId: emailId,
          mobileNo: mobileNo,
          storeId: storeId,
          appointmentDate: appointmentDate,
          appointmentfromTime: appointmentFromTime,
          appointmentoTime: appointmentoTime,
          appointmentType: "Custom Fit",
          appointmentStatus: "Booked",
          createdBy: 0,
          isSMS: isSMS,
          isMail: isMail,
        },
      }
    );

    return response?.data;
  } catch (error) {
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const createKnownCustomerAppointment = async (formData) => {
  try {
    const response = await axiosInstanceSecured.post(
      API_ENDPOINTS.Appoinment.createKnownAppointment.toString(),
      formData
    );

    return response?.data;
  } catch (error) {
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
