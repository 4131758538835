import React, { useEffect, useState } from "react";
import UAParser from "ua-parser-js";

import {
  Button,
  Typography,
  Box,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  Skeleton,
  Chip,
  Alert,
  AlertTitle,
  colors,
  CircularProgress,
  Backdrop,
  Paper,
  FormControl,
  Divider,
  Link,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Switch,
} from "@mui/material";
import { Clear, Info } from "@mui/icons-material";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Store, Storefront, ViewComfyAltRounded } from "@mui/icons-material";
import { LocationOn } from "@mui/icons-material";
import { Info as InfoIcon } from "@mui/icons-material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { getAllStores } from "../services/Store.Service";
import PhoneIcon from "@mui/icons-material/Phone"; //
import { GetAll_Service_SubStypes } from "../services/Service.Service";
import { GetStoreAvailableData } from "../services/Store.Service";
import { getAppointmentSlot } from "../services/Store.Service";
import { createAppointment } from "../services/Store.Service";
import { authService } from "../services/auth.service";
import bannerImage from "../assets/images/CustomFit.jpg";
import { ArrowRight } from "@mui/icons-material";
import { GolfCourse } from "@mui/icons-material";
import { UpsertCustomer } from "../services/Customer.Service";
import { SearchCustomer } from "../services/Customer.Service";
import { createKnownCustomerAppointment } from "../services/Store.Service";

export default function BookingsV2() {
  const [activeStep, setActiveStep] = useState(0);
  const [storeList, setStoreList] = useState([]);
  const [SelectedStore, setSelectedStore] = useState(null);
  const [StoreDetails, setStoreDetails] = useState(null);
  const [SelectedService, setSelectedService] = useState(null);
  const [SelectedSubService, setSelectedSubService] = useState(null);
  const [ServiceList, setServiceList] = useState([]);

  const [SelectedDate, setSelectedDate] = useState(dayjs());
  const [TImeLoading, setTimeLoading] = useState(false);
  const [AvailableTimeslot, setAvailableTimeSlots] = useState([]);
  const [SelectedTime, setSelectedTime] = useState(null);

  const [Title, setTitle] = useState("");
  const [TitleName, setTitleName] = useState("");
  const [Firstname, setFirstName] = useState(null);
  const [Lastname, setlastName] = useState(null);
  const [Dob, setDob] = useState(null);
  const [Email, setEmail] = useState(null);
  const [AddressLine1, setAddressLine1] = useState("");
  const [AddressLine2, setAddressLine2] = useState("");
  const [AddressLine3, setAddressLine3] = useState("");
  const [City, setCity] = useState("");
  const [Country, setCountry] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [Mobile, setMobile] = useState(null);
  const [TeleAlert, setTeleAlert] = useState(false);
  const [MailAlert, setMailAlert] = useState(false);
  const [SmsAlert, setSmSAlert] = useState(false);
  const [EmailAlert, setEmailAlert] = useState(false);
  const [StoreUnAvailability, setStoreUnAvailability] = useState([]);
  const [StoreHolidays, setStoreHolidays] = useState([]);
  const [DisbaledDates, setDisbaledDates] = useState([]);
  const [CalendarLoading, setCalendarLoading] = useState(false);
  const [AppointmentConfirmed, setAppointmentConfirmed] = useState(false);
  const [Datasubmit, setDatasubmit] = useState(true);
  const [appointmentCode, setAppointmentcode] = useState("");
  const [SearchCusId, setSearchCusId] = useState(null);
  const [Userid, setUserId] = useState(0);

  const [ErrorList, setErrorList] = useState([]);

  useEffect(() => {
    setSearchCusId(null);
    getStoreData();
    getServiceData();
  }, []);

  useEffect(() => {
    const newStartDate = moment().startOf("month");
    const newEndDate = moment().endOf("month");
    if (SelectedStore != null) {
      getAvailableDate();
    }
  }, [SelectedStore]);

  useEffect(() => {
    getTimeSlots();
  }, [SelectedDate]);

  /// code to get the store list
  const getStoreData = async () => {
    try {
      var res = await getAllStores();
      if (res.isSuccess == true && res.statusCode == 200) {
        setStoreList(res.result.data);
      } else {
        toast.warn(res.message);
      }
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };

  /// code to get the service types

  const getServiceData = async () => {
    try {
      var res = await GetAll_Service_SubStypes();
      if (res.isSuccess == true && res.statusCode == 200) {
        console.log(
          res.result.resultSet.filter(
            (item) => item.SubServiceName != "Generic"
          )
        );
        setServiceList(
          res.result.resultSet.filter(
            (item) => item.SubServiceName != "Generic"
          )
        );
      } else {
        toast.warn(res.message);
      }
    } catch (error) {
      toast.warn("Something went wrong");
    } finally {
      setDatasubmit(false);
    }
  };
  // code to get the available dates
  const getAvailableDate = async (start, end) => {
    var UnAvailableDays = [];
    setStoreUnAvailability([]);
    setDisbaledDates([]);

    try {
      var response = await GetStoreAvailableData(SelectedStore.StoreId);

      if (response.isSuccess == true) {
        setStoreDetails(response.result);
        setStoreUnAvailability(response?.result?.StoreAvailability);
        dateCalculations(response?.result?.StoreHolidays ?? [])
        // setStoreHolidays(response?.result?.StoreHolidays);
      }
      console.log(UnAvailableDays);
    } catch (error) {
      console.log(error);
      toast.warn("Something went wrong");
    }
  };

  const dateCalculations = (holidays) => {
    const generateDates = (holidayFrom, holidayTo) => {
      const startDate = dayjs(holidayFrom);
      const endDate = dayjs(holidayTo);
      const dates = [];

      for (
        let date = startDate;
        date.isBefore(endDate) || date.isSame(endDate);
        date = date.add(1, "day")
      ) {
        dates.push(date.format("YYYY-MM-DD"));
      }

      return dates;
    };

    const uniqueDates = new Set();

    holidays.forEach((holiday) => {
      const dates = generateDates(holiday.holidayFrom, holiday.holidayTo);
      dates.forEach((date) => uniqueDates.add(date));
    });

    const sortedUniqueDates = Array.from(uniqueDates).sort();
    setStoreHolidays(sortedUniqueDates)
  };

  const getDisabledDates = async (
    UnAvailableDays,
    startOfMonth,
    endOfMonth
  ) => {
    try {
      setDisbaledDates([]);
      setCalendarLoading(true);
      const dates = [];
      const startDate = startOfMonth;
      const endDate = endOfMonth;

      const weekdayIndices = UnAvailableDays.map((weekday) =>
        moment().day(weekday).day()
      );

      var current = moment(startDate).startOf("week");

      while (current.isSameOrBefore(endDate)) {
        const dayOfWeek = current.day();

        if (weekdayIndices.includes(dayOfWeek)) {
          dates.push(current.format("DD-MMM-YYYY"));
        }
        current.add(1, "day");
      }

      setDisbaledDates(dates);

      setTimeout(() => {
        setCalendarLoading(false);
      }, 200);
    } catch (error) {}
  };

  const isDateDisabled = (date) => {
    return DisbaledDates.some((disabledDate) =>
      date.isSame(moment(disabledDate), "day")
    );
  };

  // to get the appointment time slots

  const getTimeSlots = async () => {
    setTimeLoading(true);
    setAvailableTimeSlots([]);

    try {
      if (SelectedDate != null) {
        var response = await getAppointmentSlot(
          SelectedSubService.SubServiceTypeId,
          SelectedStore.StoreId,
          SelectedDate.format("YYYY-MM-DD")
        );

        var slots = [];
        if (response.isSuccess == true) {
          response.result.forEach((element) => {
            let concatTime =
              moment(element?.availableSlotFrom, [
                "HH:mm:ss",
                "h:mm:ss A",
                "h:mm:ss a",
              ]).format("HH:mm") +
              "-" +
              moment(element?.availableSlotTo, [
                "HH:mm:ss",
                "h:mm:ss A",
                "h:mm:ss a",
              ]).format("HH:mm");

            if (
              SelectedDate.format("YYYY-MM-DD") != moment().format("YYYY-MM-DD")
            ) {
              slots.push(concatTime);
            } else if (
              SelectedDate.format("YYYY-MM-DD") ==
                moment().format("YYYY-MM-DD") &&
              moment() <
                moment(element?.availableSlotTo, [
                  "HH:mm:ss",
                  "h:mm:ss A",
                  "h:mm:ss a",
                ])
            ) {
              slots.push(concatTime);
            }
          });
          setAvailableTimeSlots(slots);
        } else {
          toast.warn(response?.message);
        }
      }
    } catch (error) {
    } finally {
      setTimeLoading(false);
    }
  };

  const shouldDisableDate = (date) => {
    const dayOfWeek = date.format("dddd").toLowerCase();
    const formattedDate = date.format("YYYY-MM-DD");
    console.log("StoreUnAvailability", StoreUnAvailability);
    const isUnavailableDay = StoreUnAvailability.some(
      (availability) =>
        availability.DayOfTheWeek.toLowerCase() === dayOfWeek &&
        !availability.Availability
    );

    const isHoliday = StoreHolidays.includes(formattedDate);

    return isUnavailableDay || isHoliday;
  };

  const CheckValidations = () => {
    try {
      if (Title == null || Title.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          title: "Title is required", // Reset error message for the field
        }));
      }
      if (
        Title != null &&
        Title.trim() == "Other" &&
        (TitleName == null || TitleName.trim() == "")
      ) {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          titleName: "TitleName is required", // Reset error message for the field
        }));
      }

      if (Firstname == null || Firstname.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          firstName: "First Name is required", // Reset error message for the field
        }));
      }
      if (Lastname == null || Lastname.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          lastname: "Last Name is required", // Reset error message for the field
        }));
      }
      if (Email == null || Email.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          Email: "Email is required", // Reset error message for the field
        }));
      }

      if (AddressLine1 == null || AddressLine1.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          addressLine1: "Address Line 1 is required", // Reset error message for the field
        }));
      }
      if (City == null || City.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          City: "Town // City is required", // Reset error message for the field
        }));
      }
      if (Country == null || Country.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          Country: "Country is required", // Reset error message for the field
        }));
      }

      if (PostalCode == null || PostalCode.trim() == "") {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          PostalCode: "Postal Code is required", // Reset error message for the field
        }));
      }
      if (Mobile == null || Mobile.trim() == "" || Mobile.trim().length < 10) {
        setErrorList((prevErrors) => ({
          ...prevErrors,
          Mobile: "Contact Number is required", // Reset error message for the field
        }));
      }
    } catch (error) {}
  };

  const checkSubmitBtn =
    Title != null &&
    Title.trim() != "" &&
    (Title.trim() == "Other"
      ? TitleName != null && TitleName.trim() != ""
        ? true
        : false
      : true) &&
    Firstname != null &&
    Firstname.trim() != "" &&
    Lastname != null &&
    Lastname.trim() != "" &&
    Email != null &&
    Email.trim() != "" &&
    AddressLine1 != null &&
    AddressLine1.trim() != "" &&
    City != null &&
    City.trim() != "" &&
    Country != null &&
    Country.trim() != "" &&
    PostalCode != null &&
    PostalCode.trim() != "" &&
    Mobile != null &&
    Mobile.trim() != "" &&
    Mobile.trim().length > 9 &&
    SelectedStore != null &&
    SelectedSubService != null &&
    SelectedDate != null &&
    SelectedTime != null &&
    SelectedTime.trim() != "";

  // Book an appointment

  const deviceInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    const deviceType = result.device.type || "Unknown Device";
    const deviceModel = result.device.model || "Unknown Model";
    const osName = result.os.name || "Unknown OS";
    const osVersion = result.os.version || "Unknown Version";

    const deviceName = `${deviceType} ${deviceModel}`;

    return {
      deviceName: deviceName.trim(),
      deviceOS: `${osName} ${osVersion}`,
    };
  };

  const doubleEscapeJsonString = (obj) => {
    let jsonString = JSON.stringify(obj);

    jsonString = jsonString.replace(/\\/g, "\\\\").replace(/"/g, '\\"');

    jsonString = jsonString.replace(/\\/g, "\\\\").replace(/"/g, '\\"');

    return jsonString;
  };

  const upsertUser = async () => {
    try {
      const sourceAttribute = await deviceInfo();
      var userData = {
        customerDetails: {
          CustomerID:
            SearchCusId != null
              ? SearchCusId?.snowflakeId == null
                ? 0
                : SearchCusId?.snowflakeId
              : 0,
          title: Title == "Other" ? TitleName : Title,
          firstName: Firstname,
          lastName: Lastname,
          DOB: Dob != null ? dayjs(Dob).format("YYYY-MM-DD") : null,
          email: Email,
          countryCode: "N.A",
          mobileNumber: Mobile,
          phoneNumber: 0,
          address1: AddressLine1,
          address2: AddressLine2,
          address3: AddressLine3,
          City: City,
          state: "",
          country: Country,
          postalCode: PostalCode,
          brandStackID: SearchCusId != null ? SearchCusId?.brandStakId : null,
          Password: "Qwerty123!",
          isEmail: EmailAlert,
          isMail: MailAlert,
          isPhone: TeleAlert,
          isSMS: SmsAlert,
          integratedBy: 0,
          isEmployee: false,
          employeeId: "",
          Source: "WebBooking",
          sourceAttribute: doubleEscapeJsonString(sourceAttribute),
        },
        clubCard: [],
      };

      const upsert = await UpsertCustomer(userData);
      console.log(upsert);
      if (upsert?.returnData?.Success == true) {
        console.log(upsert?.returnData.CustomerId);
        setUserId(upsert?.returnData.CustomerId);
      } else if (
        upsert?.returnData.Success == false &&
        upsert?.returnData.StatusCode == 500
      ) {
        toast.warn(upsert?.returnData.Message);
      }

      return upsert;
    } catch (error) {
      toast.warn("Something went wrong");
      console.log(error);
    }
  };

  const getCustomerId = async () => {
    try {
      const customerID = await SearchCustomer(Email.trim());

      if (
        customerID?.customerSearchResult?.Success == true &&
        customerID?.customerSearchResult?.totalSize > 0
      ) {
        setSearchCusId(customerID?.customerSearchResult?.customerDetails[0]);

        return customerID?.customerSearchResult?.customerDetails[0];
      } else {
        setSearchCusId(null);
        return null
      }
    } catch (error) {
      return null
    }
  };


  const BookKnowUserAppointment = async (cusData) => {
    try {
      var cusId = cusData ?? null;
      if (cusId == null || cusId?.snowflakeId < 1) {
        cusId = await getCustomerId(Email);
      }

      if (
        (cusId != null && cusId?.snowflakeId > 0) ||
        (SearchCusId != null && SearchCusId?.snowflakeId > 0)
      ) {
        var data = {
          appointmentDetails: {
            serviceTypeId: SelectedSubService.serviceTypeId,
            serviceSubTypeId: SelectedSubService.SubServiceTypeId,
            expertId: 0,
            customerId:
              cusId != null ? cusId?.snowflakeId : SearchCusId?.snowflakeId,
            storeId: SelectedStore.StoreId,
            appointmentDate: SelectedDate.format("YYYY-MM-DD"),
            appointmentFromTime: SelectedTime.split("-")[0],
            appointmentoTime: SelectedTime.split("-")[1],
            appointmentType: "Custom Fit",
            appointmentStatus: "Booked",
            source: "WebBooking",
          },
        };

        var resposne = await createKnownCustomerAppointment(data);
        if (resposne?.result?.returnData?.Success === true) {
          setAppointmentConfirmed(true);
          setAppointmentcode(resposne.result.returnData.AppointmentCode);
        } else {
          setAppointmentConfirmed(false);
          toast.warn(resposne?.returnData?.Message);
        }
      } else {
        setDatasubmit(false);
      }
    } catch (error) {
      toast.warn("Something went wrong");
    } finally {
      setDatasubmit(false);
    }
  };

  const sendRequest = async () => {
    try {
      setDatasubmit(true);
      setAppointmentcode("");
      let cusId = await getCustomerId()
      console.log(SearchCusId);
      if (cusId == null || cusId?.snowflakeId < 1) {
        var upsert = await upsertUser();
        if (
          upsert?.returnData.Success == false &&
          upsert?.returnData.StatusCode == 500
        ) {
          setDatasubmit(false);
          return;
        }
        else {
          let obj = {
            snowflakeId: upsert?.returnData?.CustomerId || 0
          }
          cusId = obj
        }
      }

      await BookKnowUserAppointment(cusId);
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };

  return (
    <Container sx={{ backgroundColor: "white" }}>
      <img
        src={bannerImage}
        width={"100%"}
        style={{ filter: "grayscale(60%)" }}
      />
      {AppointmentConfirmed ? (
        <Grid
          container
          spacing={4}
          columns={12}
          sx={{
            paddingLeft: {
              xs: 1, // for extra-small screens
              sm: 2, // for small screens
              md: 10, // for medium screens
            },
            paddingRight: {
              xs: 1, // for extra-small screens
              sm: 2, // for small screens
              md: 10, // for medium screens
            },
          }}
        >
          <Grid item xs={12}>
            <Typography
              Typography
              variant="h2"
              color="rgb(178, 0, 33)"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Appointment Booked
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              Thank you{" "}
              <Typography
                variant="subtitle1"
                display={"inline"}
                sx={{ fontWeight: "bold", color: "rgb(178, 0, 33)" }}
              >
                {Firstname}
                {", "}
              </Typography>{" "}
              Your{" "}
              <Typography
                variant="subtitle1"
                display={"inline"}
                sx={{ fontWeight: "bold", color: "rgb(178, 0, 33)" }}
              >
                {SelectedSubService?.ServiceName}{" "}
              </Typography>
              appointment at{" "}
              <Typography
                variant="subtitle1"
                display={"inline"}
                sx={{ fontWeight: "bold", color: "rgb(178, 0, 33)" }}
              >
                {SelectedStore?.Name}{" "}
              </Typography>{" "}
              has been successfully booked.{" "}
              <Typography
                variant="subtitle1"
                display={"inline"}
                sx={{ color: "#555" }}
              >
                We've sent a confirmation email with all the details.
              </Typography>
            </Typography>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              We've sent a confirmation email with all the details.
            </Typography>
          </Grid> */}
          {/* 
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Thank you {Firstname}
            </Typography>

            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              Your booking is now confirmed.
            </Typography>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Booking reference:
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              {appointmentCode}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Store:
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              {SelectedStore.Name}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Appointment:
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              {SelectedSubService.ServiceName} /{" "}
              {SelectedSubService.SubServiceName} at {SelectedStore.Name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Date/Time:
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              {SelectedDate && SelectedDate.format("DD-MMM-YYYY")} /{" "}
              {SelectedTime} ({SelectedSubService.Duration} Minutes)
            </Typography>
          </Grid>

          <Grid item xs={12} marginTop={10}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555" }}>
              We look forward to seeing you soon in store
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4} sx={{ paddingX: "30px", marginTop: "1%" }}>
          <Grid item xs={12} md={6}>
            {/* <Grid
              item
              sx={{
                backgroundColor: "rgb(178, 0, 33)",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                paddingY: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="h3" color={"white"}>
                Contact Information (Step-1)
              </Typography>
            </Grid> */}
            <Paper
              elevation={3}
              style={{
                padding: 15,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 4px",
                border: "0px",
                paddingTop: "25px",
              }}
            >
              <legend
                style={{
                  fontWeight: "bold",
                  fontFamily: "Source Sans Pro, sans-serif",
                  fontSize: "20px",
                  lineHeight: 1.235,
                  marginTop: "-40px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "white",
                    color: "rgb(178, 0, 33)",
                  }}
                >
                  Contact Information (Step-1)
                </span>
              </legend>
              <Grid item marginTop={"20px"}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    sx={{ width: "25%", mr: 1 }}
                    error={
                      ErrorList?.title && ErrorList?.title.length > 1
                        ? true
                        : false
                    }
                  >
                    <InputLabel id="demo-simple-select-label">
                      Title *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Title}
                      label="Select"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      onBlur={(e) =>
                        e.target.value.length > 1 &&
                        setErrorList(delete ErrorList?.title)
                      }
                    >
                      <MenuItem value={"Mr"}>Mr</MenuItem>
                      <MenuItem value={"Mrs"}>Mrs</MenuItem>
                      <MenuItem value={"Ms"}>Ms</MenuItem>
                      <MenuItem value={"Miss"}>Miss</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  {Title == "Other" ? (
                    <TextField
                      required
                      value={TitleName}
                      onChange={(e) => {
                        setTitleName(e.target.value);
                      }}
                      sx={{ width: "74%", my: 1 }}
                      id="outlined-basic"
                      label="Title Name"
                      variant="outlined"
                      onBlur={(e) =>
                        e.target.value.length > 1 &&
                        setErrorList(delete ErrorList?.TitleName)
                      }
                      InputProps={{
                        endAdornment: TitleName?.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => setTitleName("")}
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      required
                      value={Firstname}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      sx={{ width: "74%", my: 1 }}
                      id="outlined-basic"
                      label="First Name"
                      autoComplete="off"
                      variant="outlined"
                      error={ErrorList?.firstName ? true : false}
                      onBlur={(e) =>
                        e.target.value.length > 1 &&
                        setErrorList(delete ErrorList?.firstName)
                      }
                      InputProps={{
                        endAdornment: Firstname?.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => setFirstName("")}
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Box>
              </Grid>
              {Title == "Other" && (
                <Grid item style={{ marginTop: "15px" }}>
                  <TextField
                    required
                    value={Firstname}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="First Name"
                    autoComplete="off"
                    variant="outlined"
                    error={ErrorList?.firstName ? true : false}
                    onBlur={(e) =>
                      e.target.value.length > 1 &&
                      setErrorList(delete ErrorList?.firstName)
                    }
                    InputProps={{
                      endAdornment: Firstname?.length > 0 && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setFirstName("")}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={Lastname}
                  onChange={(e) => {
                    setlastName(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Last Name"
                  autoComplete="off"
                  variant="outlined"
                  error={ErrorList?.lastname ? true : false}
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.lastname)
                  }
                  InputProps={{
                    endAdornment: Lastname?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setlastName("")}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <LocalizationProvider
                  localeText={{
                    fieldMonthPlaceholder: (params) =>
                      params.contentType === "digit" ? "MM" : params.format,
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    label="Date of Birth"
                    sx={{ width: "100%" }}
                    value={Dob}
                    onChange={(newValue) =>
                      dayjs() > newValue ? setDob(newValue) : setDob(null)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps }}
                        fullWidth={true}
                      />
                    )}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => setDob(null),
                      },
                      textField: { placeholder: "DD-MMM-YYYY" },
                      input: {
                        readOnly: true,
                      },
                    }}
                    maxDate={dayjs()}
                    format="DD-MMM-YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={Email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  helperText={
                    Email && !/\S+@\S+\.\S+/.test(Email)
                      ? "Invalid email address"
                      : null
                  }
                  error={
                    Email && !/\S+@\S+\.\S+/.test(Email)
                      ? true
                      : false || ErrorList?.Email
                      ? true
                      : false
                  }
                  onBlur={(e) => {
                    if (
                      e.target.value.trim().length > 1 &&
                      /\S+@\S+\.\S+/.test(e.target.value.trim())
                    ) {
                      setErrorList(delete ErrorList?.Email);
                      // getCustomerId();
                    }
                  }}
                  autoComplete="off"
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  InputProps={{
                    endAdornment: Email?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setEmail("")}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={AddressLine1}
                  autoComplete="off"
                  onChange={(e) => {
                    setAddressLine1(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Address Line 1"
                  variant="outlined"
                  error={
                    ErrorList?.addressLine1 &&
                    ErrorList?.addressLine1.length > 2
                      ? true
                      : false
                  }
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.addressLine1)
                  }
                  InputProps={{
                    endAdornment: AddressLine1?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setAddressLine1("")}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  value={AddressLine2}
                  autoComplete="off"
                  onChange={(e) => {
                    setAddressLine2(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Address Line 2"
                  variant="outlined"
                  InputProps={{
                    endAdornment: AddressLine2?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setAddressLine2("")}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  value={AddressLine3}
                  autoComplete="off"
                  onChange={(e) => {
                    setAddressLine3(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Address Line 3"
                  variant="outlined"
                  InputProps={{
                    endAdornment: AddressLine3?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setAddressLine3("")}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={City}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Town / City"
                  autoComplete="off"
                  variant="outlined"
                  error={
                    ErrorList?.City && ErrorList?.City.length > 2 ? true : false
                  }
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.City)
                  }
                  InputProps={{
                    endAdornment: City?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setCity("")}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={Country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Country"
                  variant="outlined"
                  autoComplete="off"
                  error={
                    ErrorList?.Country && ErrorList?.Country.length > 2
                      ? true
                      : false
                  }
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.Country)
                  }
                  InputProps={{
                    endAdornment: Country.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setCountry("")}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={PostalCode}
                  autoComplete="off"
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  error={
                    ErrorList?.PostalCode && ErrorList?.PostalCode.length > 2
                      ? true
                      : false
                  }
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.PostalCode)
                  }
                  fullWidth
                  id="outlined-basic"
                  label="Postal Code"
                  variant="outlined"
                  InputProps={{
                    endAdornment: PostalCode?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setPostalCode("")}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <TextField
                  required
                  value={Mobile}
                  autoComplete="off"
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Contact Number"
                  variant="outlined"
                  error={
                    Mobile != null &&
                    Mobile.trim != null &&
                    Mobile.trim().length <= 9
                      ? true
                      : false ||
                        (ErrorList?.Mobile && ErrorList?.Mobile.length > 2)
                      ? true
                      : false
                  }
                  onBlur={(e) =>
                    e.target.value.length > 1 &&
                    setErrorList(delete ErrorList?.Mobile)
                  }
                  onKeyDown={(event) => {
                    const { key, target } = event;
                    const { value } = target;
                    // Allow navigation keys, backspace, and delete
                    if (
                      key === "Backspace" ||
                      key === "Delete" ||
                      key === "ArrowLeft" ||
                      key === "ArrowRight" ||
                      key === "Tab" //||
                      // (key === "+" && !value.includes("+")) // Allow '+' if not already included
                    ) {
                      return;
                    }

                    // Prevent non-numeric characters
                    if (!/^\d$/.test(key)) {
                      event.preventDefault();
                    }
                  }}
                  helperText={
                    Mobile != null &&
                    Mobile.trim != null &&
                    Mobile.trim().length <= 9
                      ? "Enter a valid contact number "
                      : null
                  }
                  InputProps={{
                    endAdornment: Mobile?.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setMobile("")}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "15px" }}>
                <Box
                  p={1}
                  sx={{ backgroundColor: "#F5F4F3", marginBottom: "10px" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "black" }}
                  >
                    Stay informed about our latest news and offers
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "GrayText",
                      marginTop: "5px",
                    }}
                  >
                    By signing up, you agree to receive marketing communications
                    from American Golf. We respect your privacy and are
                    committed to protecting your personal data.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "GrayText",
                      marginTop: "5px",
                    }}
                  >
                    Your information will only be used to send you updates,
                    promotions, and relevant news. You can unsubscribe at any
                    time.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "GrayText",
                      marginTop: "5px",
                    }}
                  >
                    You can choose your marketing preferences below. I would
                    like to receive up to date news, offers and promotions by;
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", color: "#555" }}
                >
                  Marketing Consent
                </Typography>
                <Stack direction={"row"} useFlexGap sx={{ flexWrap: "wrap" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        required
                        value={EmailAlert}
                        onChange={(e) => setEmailAlert(e.target.checked)}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    required
                    control={
                      <Switch
                        value={SmsAlert}
                        onChange={(e) => setSmSAlert(e.target.checked)}
                      />
                    }
                    label="SMS"
                  />
                  <FormControlLabel
                    required
                    control={
                      <Switch
                        value={TeleAlert}
                        onChange={(e) => setTeleAlert(e.target.checked)}
                      />
                    }
                    label="Telephone"
                  />
                  <FormControlLabel
                    required
                    control={
                      <Switch
                        value={MailAlert}
                        onChange={(e) => setMailAlert(e.target.checked)}
                      />
                    }
                    label="Mail"
                  />
                </Stack>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <Grid
              item
              sx={{
                backgroundColor: "rgb(178, 0, 33)",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                paddingY: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="h3" color={"white"}>
                Booking Information (Step-2)
              </Typography>
            </Grid> */}
            <Paper
              elevation={3}
              style={{
                padding: 15,
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 4px",
                border: "0px",
              }}
            >
              <legend
                style={{
                  fontWeight: "bold",
                  fontFamily: "Source Sans Pro, sans-serif",
                  fontSize: "20px",
                  lineHeight: 1.235,
                  marginTop: "-30px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "white",
                    color: "rgb(178, 0, 33)",
                  }}
                >
                  Booking Information (Step-2)
                </span>
              </legend>
              <Grid item sx={{ marginTop: "30px" }}>
                <Autocomplete
                  disablePortal
                  id="StoreDropdown"
                  value={SelectedStore}
                  getOptionLabel={(option) => option?.Name}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                    setSelectedSubService(null);
                    setSelectedTime("");
                  }}
                  disabled={TImeLoading}
                  options={storeList}
                  renderInput={(params) => (
                    <TextField {...params} label="Search a store" />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.StoreId}
                      style={{ opacity: !option.Status ? 0.5 : 1 }}
                    >
                      {option.Name}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.StoreId === value.StoreId
                  }
                  getOptionDisabled={(option) => !option.Status}
                />
              </Grid>
              {SelectedStore && (
                <Grid item sx={{ marginTop: "30px" }}>
                  <Autocomplete
                    getOptionLabel={(option) =>
                      `${option.ServiceName} / ${option.SubServiceName}`
                    }
                    disabled={TImeLoading}
                    value={SelectedSubService}
                    onChange={(event, newValue) => {
                      setSelectedSubService(newValue);
                      setSelectedDate(dayjs());
                      setSelectedTime("");
                    }}
                    id="ServiceDropdown"
                    options={ServiceList.sort(
                      (a, b) => -a.ServiceName.localeCompare(b.ServiceName)
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search a Service"
                        helperText={
                          SelectedSubService
                            ? `The duration of the selected service is ${SelectedSubService.Duration} minutes`
                            : null
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.SubServiceTypeId}>
                        {option.SubServiceName}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.SubServiceTypeId === value.SubServiceTypeId
                    }
                    groupBy={(option) => option.ServiceName}
                  />
                </Grid>
              )}

              {SelectedStore && SelectedSubService && (
                <Grid
                  item
                  sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      defaultValue={dayjs()}
                      minDate={dayjs()}
                      maxDate={dayjs().add(6, "months")}
                      displayStaticWrapperAs="desktop"
                      value={SelectedDate || null}
                      onChange={(newValue) => setSelectedDate(newValue)}
                      shouldDisableDate={shouldDisableDate}
                    />
                  </LocalizationProvider>
                </Grid>
              )}

              {SelectedStore && SelectedSubService && SelectedDate && (
                <Grid item sx={{ marginTop: "30px" }}>
                  <Typography variant="h4">Available time slots</Typography>

                  {SelectedDate && TImeLoading && (
                    <Typography
                      variant="subtitle2"
                      color={"primary"}
                      sx={{ marginTop: "5px" }}
                    >
                      Getting the available time slots{" "}
                      <CircularProgress color="inherit" size={"20px"} />
                    </Typography>
                  )}

                  {!TImeLoading &&
                    SelectedDate &&
                    AvailableTimeslot.length < 1 && (
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        sx={{ marginTop: "5px" }}
                      >
                        No slots available
                      </Typography>
                    )}
                  <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                      flexWrap: "wrap",
                      gap: 2,
                      marginTop: "20px",
                    }}
                  >
                    {SelectedDate &&
                      AvailableTimeslot.map((item) => (
                        <Chip
                          label={item}
                          variant="filled"
                          clickable={true}
                          disabled={item.isEnable < 1}
                          onClick={() => {
                            setSelectedTime(item);
                            CheckValidations();
                          }}
                          color={SelectedTime == item ? "primary" : "default"}
                        />
                      ))}
                  </Stack>
                </Grid>
              )}

              <Grid item sx={{ marginTop: "30px", textAlign: "center" }}>
                <Button
                  variant="contained"
                  disabled={!checkSubmitBtn}
                  onClick={() => sendRequest()}
                >
                  Book an appointment
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
      <Box
        sx={{
          textAlign: "right",
          paddingY: 2,
          backgroundColor: "white",
        }}
      >
        <Divider />

        <Typography variant="subtitle1" color={"GrayText"}>
          For more details on how we manage your data, please review our &nbsp;
          <Link
            href="https://www.americangolf.co.uk/content/privacy-policy.html"
            variant="subtitle1"
            color={"#1B2F46"}
            sx={{ marginRight: "20px", fontWeight: "bold" }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={Datasubmit}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
