import { Store } from "@mui/icons-material";

const apiEndpoints = {
  BASE_URL: process.env.REACT_APP_URL,
  BASE_PUBLIC_URL: process.env.REACT_APP_AUTH_URL,
  STORE: {
    GETALLSTORES: "Public/GetStores/GetAllStores",
    GET_STORE_AVAILABLE: `Public/GetStores/ByID`,
    GET_STORE_SLOTS: `Public/GetAppointmentSlot`,
  },
  LOGIN: {
    LOGIN_USER: "Account/Login",
    FORGET_PASSWORD: "Account/Password/Forget",
    RESET_PASSWORD: "Account/Password/Reset",
    CHANGE_PASSWORD: "Account/Password/Change",
  },
  Customer: {
    UpsertCustomer: "Public/UpsertCustomer",
    GetCustomer: "Public/GetCustomers",
  },
  Service: {
    GET_ALL_SERVICE: `Public/GetServicesTypes`,
  },
  Appoinment: {
    createAppointment: `Appointments/Web/CreateAppointment`,
    createKnownAppointment: `Public/Create`,
  },
};

export default apiEndpoints;
